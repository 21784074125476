// InstitutoAutismo.js
import React, { useState, useEffect } from 'react';
import Header from '../../components/siderbar';
import './styles.css';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { getAuthHeaders } from '../../services/authHeaders';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function EditarPerfil() {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        nome_completo: '',
        email: '',
        data_nascimento: '',
        profissao: ''
    });

    const navigate = useNavigate();
    const userId = localStorage.getItem('userId'); 

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await fetch(`https://apiprojetotea.squareweb.app/usuario/${userId}`, {
                    method: 'GET',
                    headers: getAuthHeaders(),
                });
                if (response.ok) {
                    const data = await response.json();
                    setFormData({
                        nome_completo: data.usuario.nome_completo,
                        email: data.usuario.email,
                        data_nascimento: data.usuario.data_nascimento,
                        profissao: data.usuario.profissao
                    });
                } else {
                    console.error(t("fetch_user_data_error"));
                }
            } catch (error) {
                console.error(t("server_connection_error"), error);
            }
        };

        fetchUserProfile();
    }, [userId, t]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const navBack = () => {
        navigate(-1);
    };

    const handleSave = async () => {
        try {
            const response = await fetch(`https://apiprojetotea.squareweb.app/usuario/${userId}`, {
                method: 'PUT',
                headers: {
                    ...getAuthHeaders(),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: t("success_title"),
                    text: t("data_updated_success"),
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#4a63d0'
                }).then(() => {
                    navigate('/perfil-user'); 
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: t("error_title"),
                    text: t("data_update_error"),
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#4a63d0'
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: t("connection_error_title"),
                text: t("server_connection_error"),
                confirmButtonText: 'OK',
                confirmButtonColor: '#4a63d0'
            });
        }
    };

    return (
        <Header>
            <div className="mchat-container">
                <div className="mchat-header">
                    <h2><FaArrowLeft className='button-navBack' onClick={navBack} /> {t("edit_profile")}</h2>
                </div>

                <div className="mchat-form">
                    <input
                        type="text"
                        name="nome_completo"
                        placeholder={t("full_name")}
                        value={formData.nome_completo}
                        onChange={handleChange}
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder={t("email_placeholder")}
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <input
                        type="date"
                        name="data_nascimento"
                        placeholder={t("dob_placeholder_date")}
                        value={formData.data_nascimento}
                        onChange={handleChange}
                    />
                    <input
                        type="text"
                        name="profissao"
                        placeholder={t("profession")}
                        value={formData.profissao}
                        onChange={handleChange}
                    />
                </div>

                <div className="button-org-info-cadastro-m-chat">
                    <button className="continue-btn" onClick={handleSave}>
                        {t("save_button")} <FaArrowRight />
                    </button>
                </div>
            </div>
        </Header>
    );
}

export default EditarPerfil;
